import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter) 
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/institutions',
    name: 'institutions',
    component: () => import('../views/institutions.vue')
  },
  {
    path: '/dynamic',
    name: 'dynamic',
    component: () => import('../views/dynamic.vue')
  },
  {
    path: '/dynamicDetalis',
    name: 'dynamicDetalis',
    component: () => import('../views/dynamicDetalis.vue')
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('../views/join.vue')
  },
  {
    path: '/productInformation',
    name: 'productInformation',
    component: () => import('../views/productInformation.vue')
  },
  {
    path: '/productInformationDetalis',
    name: 'productInformationDetalis',
    component: () => import('../views/productInformationDetalis.vue')
  },
  {
    path: '/detalisCN',
    name: 'detalisCN',
    component: () => import('../views/productInformationDetalisCN.vue')
  },
  {
    path: '/detalisEN',
    name: 'detalisEN',
    component: () => import('../views/productInformationDetalisEN.vue')
  },
  {
    path: '/detalisENs',
    name: 'detalisENs',
    component: () => import('../views/productInformationDetalisENs.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  }
})

export default router
