import request from '../utils/request';
import qs from "qs";   
const baseUrl = '/api'  
// const baseimgurl = 'http://yunsiliconn.niceued.com'

export const baseHostUrl = query => { 
    return window.location.origin
    // return baseimgurl
};

export const getheadfootdata = query => {
    return request({
        url: `${baseUrl}/Homepage/getheadfootdata`,
        method: 'get',
        params: query
    });
};

export const index = query => {
    return request({
        url: `${baseUrl}/Homepage/index`,
        method: 'get',
        params: query
    });
};

export const getorgandata = query => {
    return request({
        url: `${baseUrl}/Homepage/getorgandata`,
        method: 'get',
        params: query
    });
};

export const getdynamicsdata = query => {
    return request({
        url: `${baseUrl}/Homepage/getdynamicsdata`,
        method: 'get',
        params: query
    });
};

export const getdydetailsdata = query => {
    return request({
        url: `${baseUrl}/Homepage/getdydetailsdata`,
        method: 'get',
        params: query
    });
};
export const getjoinusdata = query => {
    return request({
        url: `${baseUrl}/Homepage/getjoinusdata`,
        method: 'get',
        params: query
    });
};
export const getproductsdata = query => {
    return request({
        url: `${baseUrl}/Homepage/getproductsdata`,
        method: 'get',
        params: query
    });
};
export const getproductsdataPost = data => {
    return request({ 
        url: `${baseUrl}/product/list`,
        method: 'post',
        data: data
    });
}; 
export const WXshares = shareurl => {  
    console.log(shareurl)
    return request({ 
        url: `/share/shares.php`, 
        // url: `http://yunsiliconn.niceued.com/share/shares.php`, 
        method: 'post',  
        headers: {
            'Content-Type': 'application/json'
        },  
        data: {url:shareurl}
    });
}; 